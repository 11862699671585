var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col float-right" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-update-btn", {
                attrs: {
                  name: "updateBtn",
                  data: _vm.param.regUpdateBtnData,
                  btnEditable: _vm.btnEditable,
                  flagCondition: _vm.flagCondition,
                },
                on: { back: _vm.back },
              }),
              _c("c-btn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOld && !_vm.updateDisabled,
                    expression: "isOld && !updateDisabled",
                  },
                ],
                attrs: {
                  label: "LBLREMOVE",
                  editable: _vm.editable,
                  icon: "delete_forever",
                },
                on: { btnClicked: _vm.remove },
              }),
              _c("c-btn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOld && !_vm.updateDisabled,
                    expression: "isOld && !updateDisabled",
                  },
                ],
                attrs: {
                  isSubmit: _vm.isComplete,
                  url: _vm.completeUrl,
                  param: _vm.tabParam,
                  mappingType: "PUT",
                  label: "LBLCOMPLETE",
                  icon: "check",
                },
                on: {
                  beforeAction: _vm.completeAccident,
                  btnCallback: _vm.completeCallback,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.param,
                    disabled: _vm.disabled,
                    tabParam: _vm.tabParam,
                  },
                  on: { saveCallback: _vm.saveCallback },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }