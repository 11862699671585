<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <c-update-btn 
          name="updateBtn"
          :data="param.regUpdateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <!-- 탭으로 삭제 변경 -->
        <c-btn v-show="isOld && !updateDisabled" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="remove" />
        <!-- 완료 -->
        <c-btn 
          v-show="isOld && !updateDisabled" 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="tabParam"
          mappingType="PUT"
          label="LBLCOMPLETE" 
          icon="check"
          @beforeAction="completeAccident"
          @btnCallback="completeCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="param"
          :disabled="disabled"
          :tabParam="tabParam"
          @saveCallback="saveCallback"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-accident-register',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        regUpdateBtnData: {
          title: '사고등록',
          flag: false,
          research: '',
          planResearch: '',
          recResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tabParam: {
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        witnessUserId: '',
        witnessDeptCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        humanDamage: '',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        toxicFlag: 'N',
        accidentType: '',
        accidentEtc: '',
        accidentMachineCd: null,
        damageClassCd: null,
        woundCd: null,
        accidentVictimModelList: [], // 관련 사고자
        deleteAccidentVictimModelList: [], // 사고자 삭제 모델
      },
      completeUrl: '',
      isComplete: false,
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      editable: true,
      splitter: 5,
      tab: 'processAccidentRegisterInfo',
      // tabItems: [],
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    tabItems() {
      return [ 
        { key: uid(), name: 'processAccidentRegisterInfo', icon: 'edit', label: '사고정보', component: () => import(`${'./processAccidentRegisterInfo.vue'}`) },
        { key: uid(), name: 'processRelationPicture', icon: 'camera_enhance', label: '사고관련<br>사진', component: () => import(`${'./processRelationPicture.vue'}`),  },
      ]
    },
    isOld() {
      return Boolean(this.tabParam.iimAccidentId)
    },
    updateDisabled() {
      return Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd !== 'ISPC000001'
    },
    disabled() {
      return Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd !== 'ISPC000001' && !this.param.regUpdateBtnData.flag || this.tabParam.approvalStatusCd === 'ASC0000001'
    },
    btnEditable() {
      return this.editable && (this.tabParam.accidentStatusCd !== 'ISPC000005' && this.tabParam.accidentStatusCd !== 'ISPC000001') && Boolean(this.tabParam.iimAccidentId) && this.tabParam.approvalStatusCd !== 'ASC0000001'
    },
    flagCondition() {
      return this.tabParam.accidentStatusCd === 'ISPC000001' 
    },
  },
  watch: {
    'param.regUpdateBtnData.recResearch'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.process.complete.url

      if (!this.param.iimAccidentId) {
        this.tabItems[1].disabled = true;
      }
      this.getDetail();
    },
    getDetail() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tabParam = _result.data;
        },);
      }
    },
    completeAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '사고등록 하시겠습니까?', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.accidentStatusCd = 'ISPC000003'
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.tabParam.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.tabParam.accidentStatusCd 
        })
      }
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(data) {
      this.$set(this.param, 'iimAccidentId', data)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: this.param.iimAccidentId
      })
      this.tabItems[1].disabled = false;
      this.getDetail();
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
     back() {
      this.getDetail();
    },
  }
};
</script>
